import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/static/style/reset.css'
Vue.config.productionTip = true
// import '../autosize.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message, ElDialog } from 'element-ui';
Vue.use(ElementUI);

// import animated from 'animate.css'
import Plugin from 'v-fit-columns';
// Vue.use(animated)
Vue.use(Plugin);

// https://xnjdlx.wm71.mttest.cn
Vue.prototype.$http_upfile = "https://xnjdlx.wm80.mtnet.ren/api/index/file"
Vue.prototype.$http_upimg = "https://xnjdlx.wm80.mtnet.ren/api/index/upload"
Vue.prototype.$exportfile = "https://xnjdlx.wm80.mtnet.ren/api/Excel/person_derive"
Vue.prototype.$export_account = "https://xnjdlx.wm80.mtnet.ren/api/Excel/export_account"
Vue.prototype.$export_applylist = "https://xnjdlx.wm80.mtnet.ren/api/Excel/apply_export"



Vue.prototype.$exportLxResult = "https://xnjdlx.wm80.mtnet.ren/api/Excel/derive_choose?keyword=&clause_id="
Vue.prototype.$exportKhResult = "https://xnjdlx.wm80.mtnet.ren/api/Excel/derive_examine?keyword=&assess_id="

Vue.prototype.$import = "https://xnjdlx.wm80.mtnet.ren/api/Excel/imports"

Vue.prototype.$message = Message
Vue.prototype.$dialog = ElDialog


import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// 防止localStorage被修改
// window.addEventListener('storage', function (e) {
//   localStorage.setItem(e.key, e.oldValue)
// });
// // 防止sessionStorage被修改
// window.addEventListener('storage', function (e) {
//   this.sessionStorage.setItem(e.key, e.oldValue)
// });
