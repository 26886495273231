import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),

    children: [
      {
        //测试路由
        path: 'system',
        name: 'system',
        component: () => import('@/views/Systemhome.vue')
      },
      {
        path: '/system',
        name: 'system',
        component: () => import('@/views/System.vue'),
        children: [
          {
            path: 'user',
            name: 'user',
            component: () => import('../views/User.vue'),
          },
          {
            path: 'menu',
            name: 'menu',
            component: () => import('../views/Menu.vue'),
          },
          {//操作日志
            path: 'operationlog',
            name: 'operationlog',
            component: () => import('@/views/Operationlog.vue')
          },
          {//人才办审核遴选审核审批
            path: 'applicationlist',
            name: 'applicationlist',
            component: () => import('@/views/Applicationlist_lx.vue')
          },
          {//申请人
            path: 'planlist',
            name: 'planlist',
            component: () => import('@/views/Planlist.vue')
          },
          {//账号管理-申请人
            path: 'talentmanagement',
            name: 'talentmanagement',
            component: () => import('@/views/Talentmanagement.vue')
          },
          {//账号管理-教学科研单位
            path: 'teachingunit',
            name: 'teachingunit',
            component: () => import('@/views/Teachingunit.vue')
          },
          {//账号管理-评审专家
            path: 'reviewexperts',
            name: 'reviewexperts',
            component: () => import('@/views/Reviewexperts.vue')
          },
          {//账号管理-高级账号管理
            path: 'advancedaccount',
            name: 'advancedaccount',
            component: () => import('@/views/Advancedaccount.vue')
          },
          {//设置-教学科研单位管理
            path: 'setupcollegemanagement',
            name: 'setupcollegemanagement',
            component: () => import('@/views/Setupcollegemanagement.vue'),
          },
          {//设置-标签管理
            path: 'setuplabelmanagement',
            name: 'setuplabelmanagement',
            component: () => import('@/views/Setuplabelmanagement.vue')
          },
          {//设置-角色管理
            path: 'rolemanagement',
            name: 'rolemanagement',
            component: () => import('@/views/Rolemanagement.vue')
          },
          {//专家库-评审小组管理
            path: 'managementofreviewteam',
            name: 'managementofreviewteam',
            component: () => import('@/views/Managementofreviewteam.vue')
          },
          {//考核审批列表
            path: 'auditlist',
            name: 'auditlist',
            component: () => import('@/views/Auditlist.vue')
          },
          {
            //审核审批 -考核阶段
            path: 'audit',
            name: 'audit',
            component: () => import('@/views/Audit.vue')
          },
          {//人才考核管理
            path: 'assessmentmanagement',
            name: 'assessmentmanagement',
            component: () => import('@/views/Assessmentmanagement.vue')
          },
          {//人才考核列表
            path: 'appraisallist',
            name: 'appraisallist',
            component: () => import('@/views/Appraisallist.vue')
          },
          {//人才库
            path: 'talentpool',
            name: 'talentpool',
            component: () => import('@/views/Talentpool.vue')
          },
          // {//院长\人才秘书\分管院长 人才计划列表
          //   path: 'talentplanlist',
          //   name: 'talentplanlist',
          //   component: () => import('@/views/Talentplanlist.vue')
          // },
          // 遴选推荐↓
          {//人才计划配置
            path: 'talentplanconfiguration',
            name: 'talentplanconfiguration',
            component: () => import('@/views/Talentplanconfiguration.vue')
          },
          {//申请结果
            path: 'applicationresult',
            name: 'applicationresult',
            component: () => import('@/views/Applicationresult_lx.vue')
          },
          {
            path: 'applicationrecord',
            name: 'applicationrecord',
            component: () => import('@/views/Applicationrecord.vue')
          },
          {
            //聘期考核
            path: 'fixedassessment',
            name: 'fixedassessment',
            component: () => import('@/views/Midtermassessment.vue')
          },
          {
            //年度考核
            path: 'periodicassessment',
            name: 'periodicassessment',
            component: () => import('@/views/Periodicassessment.vue')
          },
          {
            //其他考核
            path: 'singleassessment',
            name: 'singleassessment',
            component: () => import('@/views/Singleassessment.vue')
          },
          {//专家个人信息
            path: 'personalinfoofexperts',
            name: 'personalinfoofexperts',
            component: () => import('@/views/Personalinfo_zj.vue')
          },
          {//专家人才计划(项目)评审
            path: 'planreview',
            name: 'planreview',
            component: () => import('@/views/Planreview.vue')
          },
          {//分配指派列表
            path: 'allocationlist',
            name: 'allocationlist',
            component: () => import('@/views/Allocationlist.vue')
          }
          ,
          {
            //考核指派
            path: 'assessmentassignment',
            name: 'assessmentassignment',
            component: () => import('@/views/Assessmentassignment.vue')
          },
          {
            //考核详情
            path: 'assessmentdetail',
            name: 'assessmentdetail',
            component: () => import('@/views/Assessmentdetail.vue')
          }
          ,
          {
            //专家考核列表
            path: 'expertaudit',
            name: 'expertaudit',
            component: () => import('@/views/Expertaudit.vue')
          }
          ,
          {
            //人才办专家评分复审
            path: 'gradingreview',
            name: 'gradingreview',
            component: () => import('@/views/Gradingreview.vue')
          }
          ,
          {
            //校领导专家评分复审
            path: 'schoolleadercheck',
            name: 'schoolleadercheck',
            component: () => import('@/views/Schoolleadercheck.vue')
          }
          ,
          {
            //人才考核记录
            path: 'assessmentrecord',
            name: 'assessmentrecord',
            component: () => import('@/views/Assessmentrecord.vue')
          }
          ,
          {
            //高级账号查看考核记录
            path: 'record',
            name: 'record',
            component: () => import('@/views/Record.vue')
          },
          {
            path: 'applicantside',
            name: 'applicantside',
            component: () => import('@/views/Applicantside.vue')
          },

          {
            path: 'expertauditlist',
            name: 'expertauditlist',
            component: () => import('@/views/Expertauditlist.vue')
          },

          ,

          {
            path: 'scoreauditlist',
            name: 'scoreauditlist',
            component: () => import('@/views/Scoreauditlist.vue')
          },
          ,

          {
            path: 'evaluation',
            name: 'evaluation',
            component: () => import('@/views/Evaluation.vue')
          },
          ,

          {
            path: 'userinfo',
            name: 'userinfo',
            component: () => import('@/views/Userinfo.vue')
          },
          {
            path: 'applicationrecordtab',
            name: 'applicationrecordtab',
            component: () => import('@/views/Applicationrecordtab.vue')
          },

















        ]
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
]
// const routes = [
//   {
//     path: '/planlist',
//     name: 'planlist',
//     component: () => import('@/views/Planlist.vue')
//   },
//   {
//     path: '/applicantside',
//     name: 'applicantside',
//     component: () => import('@/views/Applicantside.vue')
//   },

//   {
//     //年度考核
//     path: '/annualassessment',
//     name: 'annualassessment',
//     component: () => import('@/views/Annualassessment.vue')
//   },
//   {
//     //聘期考核
//     path: '/employmentperiodassessment',
//     name: 'employmentperiodassessment',
//     component: () => import('@/views/Employmentperiodassessment.vue')
//   },
//   {//账号管理 人才管理
//     path: '/talentmanagement',
//     name: 'talentmanagement',
//     component: () => import('@/views/Talentmanagement.vue')
//   },
//   {//账号管理 教学科研单位管理
//     path: '/teachingunit',
//     name: 'teachingunit',
//     component: () => import('@/views/Teachingunit.vue')
//   }
//   {//账号管理 高级账号
//     path: '/advancedaccount',
//     name: 'advancedaccount',
//     component: () => import('@/views/Advancedaccount.vue')
//   },
//   {//账号管理 教学科研单位管理
//     path: '/collegemanagement',
//     name: 'collegemanagement',
//     component: () => import('@/views/Collegemanagement.vue')
//   },
//   {//账号管理 专家管理 专家库
//     path: '/expertdatabase',
//     name: 'expertdatabase',
//     component: () => import('@/views/Expertdatabase.vue')
//   },

//   {//人才计划项目
//     path: '/talentplanproject',
//     name: 'talentplanproject',
//     component: () => import('@/views/Talentplanproject.vue')
//   },

//   {//高层次人才岗位评审
//     path: '/seniorplanreview',
//     name: 'seniorplanreview',
//     component: () => import('@/views/Seniorplanreview.vue')
//   },
//   {//考核评价评审
//     path: '/assessmentandevaluation',
//     name: 'assessmentandevaluation',
//     component: () => import('@/views/Assessmentandevaluation.vue')
//   },

//   {//负责人指派
//     path: '/assignview',
//     name: 'assignview',
//     component: () => import('@/views/Assignview.vue')
//   },


//   {//分管副院长审批列表
//     path: '/auditlistbranchdean',
//     name: 'auditlistbranchdean',
//     component: () => import('@/views/Auditlistbranchdean.vue')
//   },

//   {//院长审批列表
//     path: '/auditlistdean',
//     name: 'auditlistdean',
//     component: () => import('@/views/Auditlistdean.vue')
//   }
//   {//校领导人才计划项目
//     path: '/schoolleaderclause',
//     name: 'schoolleaderclause',
//     component: () => import('@/views/Schoolleaderclause.vue')
//   },
//   {//教师办公室
//     path: '/teacheroffice',
//     name: 'teacheroffice',
//     component: () => import('@/views/Teacheroffice.vue')
//   },
//   {//纪委办公室
//     path: '/periodoffice',
//     name: 'periodoffice',
//     component: () => import('@/views/Periodoffice.vue')
//   },
//   {//其他部门
//     path: '/otheroffice',
//     name: 'otheroffice',
//     component: () => import('@/views/Otheroffice.vue')
//   }





// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  console.log('路由守卫', to)


  const token = localStorage.getItem('token')
  // const token = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('token'))))
  if (to.path == '/login') {
    next()
  } else {
    if (token) {
      NProgress.start()
      next()
    } else {
      Vue.prototype.$message({
        type: 'warning',
        message: '请先登陆！'
      })
      NProgress.start()
      next({ path: '/login' })
    }
  }



})

router.afterEach((to, from, next) => {
  NProgress.done()

})



export default router
